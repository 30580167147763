





















import { Component, Prop } from 'vue-property-decorator'

import { FormErrorsMixin } from '../../../../../support/mixins'

import { Form } from '../../../../shared/molecules/Form'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * ... DEPRECATED ...
 */
@Component({
  name: 'EmailValidateForm',
  components: { Form }
})
export class EmailValidateForm extends FormErrorsMixin {
  @Prop({ type: Object, required: true })
  public readonly formData!: Record<string, string>

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isLoading!: boolean

  @Prop({ type: Object, required: true })
  public readonly validatorsMap!: Record<string, string>

  @Prop({ type: Function, required: true })
  public readonly onSubmitCb!: () => void

  @Prop({ type: Function, required: true })
  public readonly onFailCb!: () => void

  @Prop({ type: Function, required: true })
  public readonly onSuccessCb!: () => void
}
export default EmailValidateForm
