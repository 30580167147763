











import { Component, Vue } from 'vue-property-decorator'

import { RouteNames as ProfileRoutes } from '../../../../profile/routes'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'CompanyCreateThankYou'
})
export class CompanyCreateThankYou extends Vue {
  public async onAccountRequest (): Promise<void> {
    await this.$router.push({
      name: `profile.${ProfileRoutes.Profile}`
    })
  }
}

export default CompanyCreateThankYou
