
































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'

import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'
import { Inject, logger } from '../../../../support'

import { CompanyModel } from '../../../profile/contracts/models'
import { CompanyRepositoryType, ICompanyRepository } from '../../../profile/repositories/company'
import {
  INipValidationService,
  NipValidationServiceType
} from '../../../shared/services/nip-validation'
import { Form } from '../../../shared/molecules/Form'
import { IProfileService, ProfileServiceType } from '../../../profile/contracts'
import { ShipmentRegionsMixin } from '../../../shared/mixins/shipment-regions.mixin'
import { UserMixin } from '../../../profile/shared'

import { AuthMixin } from '../../shared'
import CreateCompanyFieldset from '../../organisms/CreateCompanyFieldset/CreateCompanyFieldset.vue'
import {
  CreateCompanyFieldsetPayload
} from '../../organisms/CreateCompanyFieldset/CreateCompanyFieldset.contracts'

import {
  COMPANY_CREATE_VIEW_CONFIG_MAP,
  COMPANY_CREATE_VIEW_KEY,
  CompanyCreateConfig
} from './CompanyCreate.config'

import EmailValidateForm from './partials/EmailValidateForm.vue'
import CompanyCreateThankYou from './partials/CompanyCreateThankYou.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CompanyCreateView>({
  name: 'CompanyCreateView',
  components: { CreateCompanyFieldset, CompanyCreateThankYou, EmailValidateForm, Form },
  async created (): Promise<void> {
    this.config = this.getComponentConfig(COMPANY_CREATE_VIEW_KEY, { ...COMPANY_CREATE_VIEW_CONFIG_MAP })
    this.disableFilledFormFields = !!this.$route.query.vatId

    /**
     * @inheritDoc
     */
    this.decideToShowBillingAddressAnnotation()

    /**
     * @inheritDoc
     */
    this.retrieveShipmentRegions(true)

    this.companyValidatorsMap = this.companyCreateValidationsMap
    this.requiredFieldNames = this.filterRequiredFields()

    await this.fetchCompanyData()
  },
  mounted (): void {
    /**
     * @inheritDoc
     */
    this.fillFormDataWithConsents()
  }
})
export class CompanyCreateView extends Mixins(AuthMixin, FormErrorsMixin, UserMixin, ShipmentRegionsMixin, StructureConfigurable) {
  @Inject(CompanyRepositoryType)
  protected readonly companyRepository!: ICompanyRepository

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(NipValidationServiceType)
  protected readonly nipValidationService!: INipValidationService

  @Inject(ProfileServiceType)
  protected readonly profileRepository!: IProfileService

  @Prop({ type: Boolean, required: false, default: true })
  public shouldValidateNip!: boolean

  protected config!: CompanyCreateConfig

  public canEdit: boolean = true
  public companyFormError: string | null = null
  public isCompanySuccessfullyCreated: boolean = false
  public isProcessing: boolean = false

  public disableFilledFormFields: boolean = true

  public companyValidatorsMap: Partial<Record<keyof CreateCompanyFieldsetPayload, string>> = {}
  public requiredFieldNames: (keyof CreateCompanyFieldsetPayload)[] = []

  public companyFormData: CreateCompanyFieldsetPayload = {
    adminEmail: '',
    adminFirstName: '',
    adminLastName: '',
    companyEmail: '',
    street: '',
    streetSecondLine: '',
    city: '',
    region: null,
    postCode: '',
    countryCode: '',
    telephone: '',
    legalName: '',
    companyName: '',
    vatTaxId: ''
  }

  public showNoBillingAddressNotification: boolean = false

  /**
   * Determines consents description
   */
  public get consents (): AnyObject[] {
    return this.describeConsents(this.getConfigProperty<AnyObject[]>('consents'))
  }

  /**
   * Determines consents description
   */
  public get consentsDescription (): AnyObject {
    return this.getConfigProperty<AnyObject>('consentsDescription')
  }

  /**
   * Create compant formData validators map
   */
  public get companyCreateValidationsMap (): Record<string, string> {
    return this.getConfigProperty<Record<string, string>>('companyCreateValidationsMap')
  }

  public get hasAllRequiredFields (): boolean {
    return this.requiredFieldNames.every(name => !!this.companyFormData[name])
  }

  /**
   * On apply changes in form request
   */
  public applyChanges (): void {
    this.disableFilledFormFields = !this.disableFilledFormFields
  }

  /**
   * Handles @error event of `Form`
   */
  public onErrors (errors: Record<string, string[]>): void {
    this.errors = errors

    if (this.canEdit) {
      this.disableFilledFormFields = false
    }
  }

  public onFail (error: Error): void {
    this.setError(error)
    this.companyFormError = error.message

    this.isProcessing = false
  }

  public onCompanyCreateSuccess (): void {
    this.isCompanySuccessfullyCreated = true
    this.isProcessing = false
  }

  /**
   * Describe consents
   * @protected
   */
  private describeConsents (consents: AnyObject[]): AnyObject[] {
    return consents.map((consent) => {
      const foundDescription = this.consentsDescription![consent.option]
      return foundDescription ? {
        ...consent,
        option: foundDescription
      } : consent
    })
  }

  public async fetchCompanyData (): Promise<void> {
    this.isProcessing = true
    try {
      const company = await this.companyRepository.getCompanyData()
      if (!company) {
        return
      }
      this.mapFormData(company)
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.isProcessing = false
    }
  }

  /**
   * Updated company
   */
  public async updateCompany (): Promise<void> {
    this.isProcessing = true
    this.errors = null

    await this.profileRepository.changeUserDetails({
      firstName: this.companyFormData.adminFirstName,
      lastName: this.companyFormData.adminLastName,
    })

    if (!this.canEdit) {
      this.isProcessing = false
      return
    }

    this.saveConsents()

    await this.companyRepository.updateCompany({
      companyEmail: this.companyFormData.adminEmail,
      legalAddress: {
        street: [this.companyFormData.street, this.companyFormData.streetSecondLine],
        city: this.companyFormData.city,
        region: this.companyFormData.region!,
        postCode: this.companyFormData.postCode,
        countryCode: this.companyFormData.countryCode,
        telephone: this.companyFormData.telephone
      },
      legalName: this.companyFormData.legalName,
      companyName: this.companyFormData.companyName,
      vatTaxId: this.companyFormData.vatTaxId
    })
  }

  /**
   * Composes companyFormData with consents config
   */
  protected fillFormDataWithConsents (): void {
    for (const consent of this.consents) {
      const foundDescription = this.consentsDescription[consent.option]
      if (foundDescription) {
        this.companyFormData = {
          ...this.companyFormData,
          [foundDescription]: false
        }
      } else {
        this.companyFormData = {
          ...this.companyFormData,
          [consent.option]: false
        }
      }
    }
  }

  /**
   * Maps company with form data
   */
  private mapFormData (company: CompanyModel): void {
    const companyRegion = company.legalAddress.region.name
      ? company.legalAddress.region.name.toLowerCase()
      : null
    const candidateRegion = companyRegion && this.shipmentRegions
      ? this.shipmentRegions.find((region) => region.name.toLowerCase() === companyRegion)
      : null

    const companyStreets = company.legalAddress.street

    if (this.user?.isVerified) {
      this.canEdit = false
    }

    this.companyFormData = CompanyCreateView.checkAndRemoveBlankSpaces({
      ...this.companyFormData,
      adminEmail: company.companyAdmin.email,
      adminFirstName: company.companyAdmin.firstName,
      adminLastName: company.companyAdmin.lastName,
      companyEmail: company.email,
      street: companyStreets[0],
      streetSecondLine: companyStreets.length > 1 ? companyStreets[1] : '',
      city: company.legalAddress.city,
      region: candidateRegion ?? null,
      postCode: company.legalAddress.postCode,
      countryCode: company.legalAddress.countryCode,
      telephone: company.legalAddress.telephone,
      legalName: company.legalName,
      companyName: company.name,
      vatTaxId: company.vatTaxId ?? this.$route.query.vatId.toString()
    })

    this.disableFilledFormFields = this.hasAllRequiredFields
  }

  private filterRequiredFields (): (keyof CreateCompanyFieldsetPayload)[] {
    return Object.entries(this.companyValidatorsMap)
      .filter(([_field, validation]) => validation.indexOf('required') >= 0)
      .map(([field, _validation]) => field as keyof CreateCompanyFieldsetPayload)
  }

  /**
   * Decides whether to show annotation when company-user has no billing address set
   * @private
   */
  private decideToShowBillingAddressAnnotation (): void {
    if (this.user) {
      const hasBillingAddress = this.user.addresses.find((address) => address.defaultBilling)
      if (!hasBillingAddress) {
        this.showNoBillingAddressNotification = !!this.$route.query.hasBilling
      }
    }
  }

  /**
   * Removes blank spaces from fields
   * (company create form fields that have not been validated in backend will receive blank spaces as a value)
   */
  private static checkAndRemoveBlankSpaces (input: CreateCompanyFieldsetPayload): CreateCompanyFieldsetPayload {
    let output: CreateCompanyFieldsetPayload = input
    for (const [key, value] of Object.entries(input)) {
      output = {
        ...output,
        [key]: value === ' ' ? '' : value
      }
    }
    return output
  }

  private saveConsents (): void {
    this.eventBus.emit('app:newsletter.consents', {
      email: this.companyFormData.adminEmail,
      firstName: this.companyFormData.adminFirstName ?? undefined,
      lastName: this.companyFormData.adminLastName ?? undefined,
      acceptContent: this.companyFormData.acceptContent,
      acceptPrivacy: this.companyFormData.acceptPrivacy,
      directMarketing: this.companyFormData.directMarketing,
      emailOffers: this.companyFormData.emailOffers,
      partnersOffers: this.companyFormData.partnersOffers,
      smsOffers: this.companyFormData.smsOffers,
    })
  }
}

export default CompanyCreateView
