import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CREATE_COMPANY_FIELDSET_KEY = 'CreateCompanyFieldset'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface CreateCompanyFieldsetConfig {
  consents: AnyObject
  consentsDescription: Record<string, string> | null
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CREATE_COMPANY_FIELDSET_MAP: CreateCompanyFieldsetConfig = {
  consents: [],
  consentsDescription: null
}
