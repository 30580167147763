





































































































































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import set from 'lodash/set'

import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'
import { SelectItem } from '../../../../dsl/atoms/Select'

import Consents from '../../../shared/molecules/Consents/Consents.vue'
import {
  GuestDetailsFormFieldsetProps as Data
} from '../../../checkout/molecules/GuestDetailsFormFieldset'
import { ShipmentRegionsMixin } from '../../../shared/mixins/shipment-regions.mixin'

import { CreateCompanyFieldsetPayload } from './CreateCompanyFieldset.contracts'
import {
  CREATE_COMPANY_FIELDSET_KEY, CREATE_COMPANY_FIELDSET_MAP,
  CreateCompanyFieldsetConfig
} from './CreateCompanyFieldset.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CreateCompanyFieldset>({
  name: 'CreateCompanyFieldset',
  components: { Consents },
  created () {
    this.config = this.getComponentConfig(CREATE_COMPANY_FIELDSET_KEY, { ...CREATE_COMPANY_FIELDSET_MAP })
    this.retrieveShipmentRegions(true)
  },
  mounted () {
    this.setCurrentRegionData()
  },
  updated () {
    this.setCurrentRegionData()
  }
})
export class CreateCompanyFieldset extends Mixins(FormErrorsMixin, ShipmentRegionsMixin, StructureConfigurable) {
  @PropSync('formData', { type: Object, required: true })
  protected _formData!: CreateCompanyFieldsetPayload

  @Prop({ type: Array, required: false, default: () => [] })
  public consents!: AnyObject[]

  @Prop({ type: Object, required: false, default: null })
  public errors!: Data['_errors']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly disableFormFields!: boolean

  /**
   * Determines whether company's email should equal administrator's email
   */
  @Prop({ type: Boolean, required: false, default: true })
  public readonly isCompanyEmailSameAsAdministrator!: boolean

  protected config!: CreateCompanyFieldsetConfig
  public countryCode: string = ''
  public regionCode: string = ''

  public get countryOptions (): Array<SelectItem> {
    return this.countriesData.map((country) => {
      return {
        label: this.$t(`_.countries.${country.id}`).toString(),
        value: country.id
      }
    })
  }

  /**
   * Retrieves a list of regions available for a specific country
   * @param countryCode
   */
  public getRegionsByCountry (countryCode: string): Array<SelectItem> | null {
    const country = this.dictionaryService.countries.find((country) => country.id === countryCode)

    return country && country.availableRegions ? country.availableRegions.map((region) => {
      return {
        label: region.name,
        value: region.code
      }
    }) : null
  }

  /**
   * Updates countryCode
   */
  public onCountryUpdate (value: string): void {
    this.countryCode = value
    this._formData.region = null

    this.updateModel('countryCode', value)
  }

  /**
   * Updates region
   */
  public onRegionUpdate (value: string): void {
    this.regionCode = value

    const country = this.dictionaryService.countries.find((country) => country.id === this.countryCode)
    if (this.shipmentRegions && country && country.availableRegions) {
      this.updateModel('region', country.availableRegions.find((region) => region.code === this.regionCode))
    }
  }

  /**
   * Updates model (works with nested objects reactivity (reference))
   * @param key - `first.second.third.x`
   * @param value - `value for key`
   */
  public updateModel (key: string, value: unknown): void {
    const newFormData = { ...this._formData }

    set(newFormData, key, value)

    if (this.isCompanyEmailSameAsAdministrator && this._formData.companyEmail) {
      set(newFormData, 'adminEmail', this._formData.companyEmail)
    }

    this._formData = newFormData

    this.$emit('update:errors')
  }

  protected setCurrentRegionData (): void {
    this.regionCode = this._formData.region?.code ? this._formData.region.code : ''
    this.countryCode = this._formData.countryCode
  }
}

export default CreateCompanyFieldset
