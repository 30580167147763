import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const COMPANY_CREATE_VIEW_KEY = 'CompanyCreateView'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface CompanyCreateConfig {
  consents: AnyObject[]
  consentsDescription: Record<string, string> | null
  companyCreateValidationsMap: Record<string, string> | null
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const COMPANY_CREATE_VIEW_CONFIG_MAP: CompanyCreateConfig = {
  consents: [],
  consentsDescription: null,
  companyCreateValidationsMap: {
    companyAdmin: 'required',
    email: 'required|string|email',
    legalAddress: 'required',
    legalName: 'required|string',
    name: 'required|string',
    vatTaxId: 'required|string'
  }
}
